<template>
  <p></p>
  <div class="container-fluid">
    <button v-if="state=='list' && is_super==true " @click="setState('new')" class="btn-success">{{ $t('currency.currencyedit.currency.addCurrencies') }}</button>
    <p></p>
    <vue-good-table v-if="state=='list' && (is_super==true || is_admin_sb==true)"
        :columns="columns"
        :rows="rows"
        :pagination-options="{
          enabled: true,
          perPage: 100,
          perPageDropdown: perPageDropdown,
          nextLabel: $t('app.vue-good-table-next.nextLabel'),
          prevLabel: $t('app.vue-good-table-next.prevLabel'),
          rowsPerPageLabel: $t('app.vue-good-table-next.rowsPerPageLabel'),
          ofLabel: $t('app.vue-good-table-next.ofLabel'),
          pageLabel: $t('app.vue-good-table-next.pageLabel'), // for 'pages' mode
          allLabel: $t('app.vue-good-table-next.allLabel')
        }"
        compactMode>
      <template #emptystate>
        <div class="vgt-center-align vgt-text-disabled">{{ $t('app.vue-good-table-next.empty') }}</div>
      </template>
      <template #table-row="props">
        <CurrencyActions v-if="props.column.field == 'actions'" :selRow="props.row.iso" :editJP="props.row.edited"></CurrencyActions>
      </template>
    </vue-good-table>

    <Currency v-if="state == 'new' || state == 'edit'" @currencySaved="getCurrencies"></Currency>
    <AddJPTemplate v-if="state == 'newJP' || state == 'editJP'"></AddJPTemplate>

  </div>
</template>

<script setup>
import { VueGoodTable } from 'vue-good-table-next';
import 'vue-good-table-next/dist/vue-good-table-next.css'
import CurrencyService from "../../../services/currencie.service";
import CurrencyActions from "./CurrencyActions.vue";
import Currency from "./Currency.vue";
import {ref, inject, computed, onMounted, provide, watch} from "vue";
import AddJPTemplate from "../../Jackpots/AddJPTemplate"

const verifyVer = inject('verifyVer')
const verifyVerData = inject('verifyVerData')

const $t = inject('$t')

const set_result = inject('set_result')

const is_super = inject('is_super')
const is_admin_sb = inject('is_admin_sb')

const sel_row = ref('')
provide('sel_row', sel_row)

const state = ref('list')
const isedit = ref([])
provide('isedit', isedit)

function setState(newState, id='') {
    state.value = newState
    sel_row.value=id
}
provide('setState', setState)

const perPageDropdown = inject('perPageDropdown')
const columns = computed(() => [
      {
        label: $t('currency.currencyedit.currency.iso'),
        field: 'iso',
        filterOptions: {enabled: true}
      },
      {
        label: $t('currency.currencyedit.currency.name'),
        field: 'currency_name',
        filterOptions: {enabled: true}
      },
      {
        label: $t('currency.currencyedit.currency.symbol'),
        field: 'symbol',
        filterOptions: {enabled: true}
      },
      {
        label: $t('currency.currencyedit.currency.usd'),
        field: 'usd',
        filterOptions: {enabled: true}
      },
      {
        label: $t('currency.currencyedit.currency.exchange_rate'),
        field: 'ex_rate',
        filterOptions: {enabled: true}
      },
      {
        label: $t('currency.currencyedit.currency.min_bet'),
        field: 'min_bet',
        filterOptions: {enabled: true}
      },
      {
        label: $t('currency.currencyedit.currency.max_bet'),
        field: 'max_bet',
        filterOptions: {enabled: true}
      },
      {
        label: $t('currency.currencyedit.currency.button1'),
        field: 'cashier_b1',
        filterOptions: {enabled: true}
      },
      {
        label: $t('currency.currencyedit.currency.button2'),
        field: 'cashier_b2',
        filterOptions: {enabled: true}
      },
      {
        label: $t('currency.currencyedit.currency.button3'),
        field: 'cashier_b3',
        filterOptions: {enabled: true}
      },
      {
        label: $t('currency.currencyedit.currency.button4'),
        field: 'cashier_b4',
        filterOptions: {enabled: true}
      },
      {
        label: $t('currency.currencyedit.currency.button5'),
        field: 'cashier_b5',
        filterOptions: {enabled: true}
      },
      {
        label: $t('currency.currencyedit.currency.button6'),
        field: 'cashier_b6',
        filterOptions: {enabled: true}
      },
      {
        label: $t('game.games.action'),
        field: 'actions',
        html: true,
        width: '100px',
      },
      {
        label: $t('currency.currencyedit.currency.precision'),
        field: 'precision',
        width: '100px',
      },
])
const rows = ref([])

function getCurrencies()
{
  CurrencyService.getCurrencies().then(
    (response) => {
      rows.value = response.data;
    },
    (error) => {
      set_result(error.response.status, error)
    }
  )
}
provide('getCurrencies', getCurrencies)
onMounted(() => {
  getCurrencies()
})
</script>
