<template>
    <button v-if="is_super == true" @click="setState('edit', selRow)" type="button" class="btn btn-primary p-1" :title="$t('public.actions.edit')">
      <div class="icon-size"><font-awesome-icon icon="pencil-alt" /></div>
    </button>

    <button v-if="editJP != null" type="button" class="btn btn-warning p-1" @click="setState('editJP', selRow)" :title="$t('public.actions.edit_template')">
      <div class="icon-size"><font-awesome-icon icon="pencil-alt" /></div>
    </button>

    <button v-else @click="setState('newJP', selRow)" type="button" class="btn btn-success p-1" :title="$t('public.actions.add_template')">
      <div class="icon-size"><font-awesome-icon icon="plus" /></div>
    </button>
</template>

<script setup>
import {ref, defineEmits, defineProps, inject, onMounted, watch} from "vue";

const $t = inject('$t')

const props = defineProps({
  selRow: String,
  editJP: String
});

const is_super = inject('is_super')

const setState = inject('setState')

</script>

<style scoped>
button {
  margin: 3px;
}

</style>