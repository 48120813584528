<template>
  <div v-if="is_super==true || is_admin_sb==true">
    <div class="row p-1 align-items-center">
      <div class="col-2">{{ $t('wizards.createpartners.currency') }}</div>
      <div class="col">
        <label>{{ sel_row }}</label>
      </div>
    </div>
    <div class="row p-1 align-items-center">
      <div class="col-2">{{ $t('billmanager.billmanagerjackpotsedit.typeofjackpot') }}</div>
      <div class="col"><label>{{ data.big.jackpot_type }}</label></div>
      <div class="col"><label>{{ data.super.jackpot_type }}</label></div>
      <div class="col"><label>{{ data.mega.jackpot_type }}</label></div>
    </div>
    <div class="row p-1">
      <label class="col-2"> {{ $t('app.public.activity') }}</label>
      <div class="col">
          <input type="checkbox" v-model="data.big.jackpot_status">
      </div>
      <div class="col">
        <input type="checkbox" v-model="data.super.jackpot_status">
      </div>
      <div class="col">
        <input type="checkbox" v-model="data.mega.jackpot_status">
      </div>
    </div>

    <div class="row p-1">
      <div class="col-2">{{ $t('billmanager.billmanagerjackpotsedit.description') }}</div>
      <div class="col"><textarea v-model="data.big.description"></textarea>
        <span v-if="validerr_b.get('description')" class="validerr">{{validerr_b.get('description')}}</span>
      </div>
      <div class="col"><textarea v-model="data.super.description"></textarea>
        <span v-if="validerr_m.get('description')" class="validerr">{{validerr_m.get('description')}}</span>
      </div>
      <div class="col"><textarea v-model="data.mega.description"></textarea>
        <span v-if="validerr_s.get('description')" class="validerr">{{validerr_s.get('description')}}</span>
      </div>
    </div>
    <div>
      <div class="row p-1">
        <div class="col-2">{{ $t('billmanager.billmanagerjackpotsedit.contributionpercentage') }}</div>
        <div class="col">
          <input type="number" step='0.01' max="100" v-model="data.big.contribution_percentage">
          <span v-if="validerr_b.get('contribution_percentage')" class="validerr">{{validerr_b.get('contribution_percentage')}}</span>
        </div>
        <div class="col">
          <input type="number" step='0.01' max="100" v-model="data.super.contribution_percentage">
          <span v-if="validerr_m.get('contribution_percentage')" class="validerr">{{validerr_m.get('contribution_percentage')}}</span>
        </div>
        <div class="col">
          <input type="number" step='0.01' max="100" v-model="data.mega.contribution_percentage">
          <span v-if="validerr_s.get('contribution_percentage')" class="validerr">{{validerr_s.get('contribution_percentage')}}</span>
        </div>
      </div>
      <div class="row p-1">
        <div class="col-2">{{ $t('billmanager.billmanagerjackpotsedit.lowerthreshold') }}</div>
        <div class="col">
          <input
              type="number"
              min="0"
              @change="change_lower_threshold"
              @keyup="change_lower_threshold"
              v-model="data.big.lower_threshold">
          <span v-if="validerr_b.get('lower_threshold')" class="validerr">{{validerr_b.get('lower_threshold')}}</span>
        </div>
        <div class="col">
          <input
              type="number"
              min="0"
              @change="change_lower_threshold"
              @keyup="change_lower_threshold"
              v-model="data.super.lower_threshold">
          <span v-if="validerr_m.get('lower_threshold')" class="validerr">{{validerr_m.get('lower_threshold')}}</span>
        </div>
        <div class="col">
          <input
              type="number"
              min="0"
              @change="change_lower_threshold"
              @keyup="change_lower_threshold"
              v-model="data.mega.lower_threshold">
          <span v-if="validerr_s.get('lower_threshold')" class="validerr">{{validerr_s.get('lower_threshold')}}</span>
        </div>
      </div>
      <div class="row p-1">
        <div class="col-2">{{ $t('billmanager.billmanagerjackpotsedit.upperthreshold') }}</div>
        <div class="col"><input min="0" type="number" v-model="data.big.upper_threshold">
          <span v-if="validerr_b.get('upper_threshold')" class="validerr">{{validerr_b.get('upper_threshold')}}</span>
        </div>
        <div class="col"><input min="0" type="number" v-model="data.super.upper_threshold">
          <span v-if="validerr_m.get('upper_threshold')" class="validerr">{{validerr_m.get('upper_threshold')}}</span>
        </div>
        <div class="col"><input min="0" type="number" v-model="data.mega.upper_threshold">
          <span v-if="validerr_s.get('upper_threshold')" class="validerr">{{validerr_s.get('upper_threshold')}}</span>
        </div>
      </div>
      <div class="row p-1">
        <div class="col-2">{{ $t('billmanager.billmanagerjackpotsedit.minbetamount') }}</div>
        <div class="col"><input type="number" min="0" v-model="data.big.min_bet_amount">
          <span v-if="validerr_b.get('min_bet_amount')" class="validerr">{{validerr_b.get('min_bet_amount')}}</span>
        </div>
        <div class="col"><input type="number" min="0" v-model="data.super.min_bet_amount">
          <span v-if="validerr_m.get('min_bet_amount')" class="validerr">{{validerr_m.get('min_bet_amount')}}</span>
        </div>
        <div class="col"><input type="number" min="0" v-model="data.mega.min_bet_amount">
          <span v-if="validerr_s.get('min_bet_amount')" class="validerr">{{validerr_s.get('min_bet_amount')}}</span>
        </div>
      </div>
    </div>
    <p class="p-2"></p>
    <button @click="Apply()" class="btn-success m-1">{{ $t('app.public.apply') }}</button>
    <button @click="Cancel()" class="btn-danger m-1">{{ $t('app.public.cancel') }}</button>
  </div>

</template>

<script setup>
import {inject, onMounted, ref, computed, provide} from "vue";
import { VueGoodTable } from 'vue-good-table-next';
import 'vue-good-table-next/dist/vue-good-table-next.css'
import {useStore} from "vuex";
import JackpotService from "@/services/jackpot.service";

const $t = inject('$t')

const getCurrencies = inject('getCurrencies')

const is_super = inject('is_super')
const is_admin_sb = inject('is_admin_sb')

const mask_percent = inject('mask_percent')
const maskpercent={ mask: 'Z*', tokens: { 'Z': { pattern: /[0-9.,]/ }}}

const set_result = inject('set_result')

const setState = inject('setState')
const sel_row = inject('sel_row')

const validatas = inject('validatas')
const validerr_b = ref(new Map())
const validerr_s = ref(new Map())
const validerr_m = ref(new Map())
const verdata_b = ref(new Map([
  ['description', {reg: '.+'}],
  ['contribution_percentage', {reg: '^[0-9]{1,2}([.][0-9]{0,2})?$|^100$', 'mess': 'возможно внести только число от 0 до 100 с точностью до сотых'}],
  ['lower_threshold', {reg: '[0-9]+'}],
  ['upper_threshold', {reg: '[0-9]+'}],
  ['min_bet_amount', {reg: '[0-9]+'}],
]));
const verdata_s = ref(new Map([
  ['description', {reg: '.+'}],
  ['contribution_percentage', {reg: '^[0-9]{1,2}([.][0-9]{0,2})?$|^100$', 'mess': 'возможно внести только число от 0 до 100 с точностью до сотых'}],
  ['lower_threshold', {reg: '[0-9]+'}],
  ['upper_threshold', {reg: '[0-9]+'}],
  ['min_bet_amount', {reg: '[0-9]+'}],
]));
const verdata_m = ref(new Map([
  ['description', {reg: '.+'}],
  ['contribution_percentage', {reg: '^[0-9]{1,2}([.][0-9]{0,2})?$|^100$', 'mess': 'возможно внести только число от 0 до 100 с точностью до сотых'}],
  ['lower_threshold', {reg: '[0-9]+'}],
  ['upper_threshold', {reg: '[0-9]+'}],
  ['min_bet_amount', {reg: '[0-9]+'}],
]));

const data = ref({
  big: {
    'jackpot_type': 'BIG',
    'description': '',
    'contribution_percentage': '',
    'lower_threshold': '',
    'upper_threshold': '',
    'min_bet_amount': '',
    'jackpot_status': '',
    'curr_id': sel_row.value
  },
  super: {
    'jackpot_type': 'SUPER',
    'description': '',
    'contribution_percentage': '',
    'lower_threshold': '',
    'upper_threshold': '',
    'min_bet_amount': '',
    'jackpot_status': '',
    'curr_id': sel_row.value
  },
  mega: {
    'jackpot_type': 'MEGA',
    'description': '',
    'contribution_percentage': '',
    'lower_threshold': '',
    'upper_threshold': '',
    'min_bet_amount': '',
    'jackpot_status': '',
    'curr_id': sel_row.value
  }
})

function Apply() {
  validerr_b.value = validatas(data.value.big, verdata_b.value)
  validerr_s.value = validatas(data.value.mega, verdata_m.value)
  validerr_m.value = validatas(data.value.super, verdata_s.value)

  if(validerr_b.value.size==0 && validerr_s.value.size==0 && validerr_m.value.size==0) {
    if (data.value.big.jackpot_status == true) {
      data.value.big.jackpot_status = 'active'
    } else {
      data.value.big.jackpot_status = 'not_active'
    }
    if (data.value.super.jackpot_status == true) {
      data.value.super.jackpot_status = 'active'
    } else {
      data.value.super.jackpot_status = 'not_active'
    }
    if (data.value.mega.jackpot_status == true) {
      data.value.mega.jackpot_status = 'active'
    } else {
      data.value.mega.jackpot_status = 'not_active'
    }


  JackpotService.saveTemplate(data.value).then(
          () => {
            getCurrencies()
            setState('list')
          },
          (error) => {
            set_result(error.response.status, error)
          }
      )
  }
}

function Cancel() {
  setState('list')
}

function getTemp(){
  JackpotService.getAllJPTemplate(sel_row.value).then(
      (response) => {
        for(let key in response.data) {
          let cur = response.data[key]

          if (cur.jackpot_status == 'active') {
            cur.jackpot_status = true
          } else {
            cur.jackpot_status = false
          }
          const jackpotType = cur.fid_jackpot_type.toLowerCase()

          if (data.value[jackpotType]) {
            data.value[jackpotType].description = cur.description
            data.value[jackpotType].contribution_percentage = cur.contribution_percentage
            data.value[jackpotType].lower_threshold = cur.lower_threshold
            data.value[jackpotType].upper_threshold = cur.upper_threshold
            data.value[jackpotType].min_bet_amount = cur.min_bet_amount
            data.value[jackpotType].jackpot_status = cur.jackpot_status
          }
        }
      },
      (error) => {
        set_result(error.response.status, error)
      }
  )
}

onMounted(
    ()=>{
      getTemp()
    }
)
</script>

<style scoped>

</style>