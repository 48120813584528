<template>
  <div class="bd-example" data-example-id="">
    <div class="form-group row">
      <label class="col-1 col-form-label">{{ $t('currency.currencyedit.currency.iso') }}</label>
      <div class="col-2">
        <input class="form-control" type="text" @change="changeISO" v-model="data.iso">
        <span v-if="validerr.get('iso')" class="validerr">{{validerr.get('iso')}}</span>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-1 col-form-label">{{ $t('currency.currencyedit.currency.name') }}</label>
      <div class="col-2">
        <input class="form-control" type="text" v-model="data.currency_name">
        <span v-if="validerr.get('currency_name')" class="validerr">{{validerr.get('currency_name')}}</span>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-1 col-form-label">{{ $t('currency.currencyedit.currency.symbol') }}</label>
      <div class="col-2">
        <input class="form-control" type="text" v-model="data.symbol">
        <span v-if="validerr.get('symbol')" class="validerr">{{validerr.get('symbol')}}</span>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-1 col-form-label">{{ $t('currency.currencyedit.currency.usd') }}</label>
      <div class="col-2">
        <input class="form-control" type="text" v-model="data.usd">
        <span v-if="validerr.get('usd')" class="validerr">{{validerr.get('usd')}}</span>
      </div>
      <div class="col-9">
        {{data.ex_rate}}
      </div>
    </div>
    <div class="form-group row">
      <label class="col-2">{{ $t('currency.currencyedit.currency.showDecimal') }}</label>
      <div>
        <label><input name="precision" type="checkbox" v-model="data.showDecimal"> </label>
      </div>
    </div>
  </div>
  <hr>
  <div class="form-group row">
    <div class="col">
      <div class="row">
        <div class="col-3">
          <label>{{ $t('currency.currencyedit.currency.min_bet') }}</label>
        </div>
        <div class="col-3">
          <input class="form-control" type="number" v-model="data.min_bet">
          <span v-if="validerr.get('min_bet')" class="validerr">{{validerr.get('min_bet')}}</span>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="row">
        <div class="col-3">
          <label>{{ $t('currency.currencyedit.currency.max_bet') }}</label>
        </div>
        <div class="col-3">
          <input class="form-control" type="number" v-model="data.max_bet">
          <span v-if="validerr.get('max_bet')" class="validerr">{{validerr.get('max_bet')}}</span>
        </div>
      </div>
    </div>
  </div>
  <hr>
  <div class="container-fluid">
    <div class="form-group row">
      <div class="col">
        <label>{{ $t('currency.currencyedit.currency.button1') }}</label>
        <input class="form-control" type="number" v-model="data.cashier_b1">
        <span v-if="validerr.get('cashier_b1')" class="validerr">{{validerr.get('cashier_b1')}}</span>
      </div>
      <div class="col">
        <label>{{ $t('currency.currencyedit.currency.button2') }}</label>
        <input class="form-control" type="number" v-model="data.cashier_b2">
        <span v-if="validerr.get('cashier_b2')" class="validerr">{{validerr.get('cashier_b2')}}</span>
      </div>
      <div class="col">
        <label>{{ $t('currency.currencyedit.currency.button3') }}</label>
        <input class="form-control" type="number" v-model="data.cashier_b3">
        <span v-if="validerr.get('cashier_b3')" class="validerr">{{validerr.get('cashier_b3')}}</span>
      </div>
      <div class="col">
        <label>{{ $t('currency.currencyedit.currency.button4') }}</label>
        <input class="form-control" type="number" v-model="data.cashier_b4">
        <span v-if="validerr.get('cashier_b4')" class="validerr">{{validerr.get('cashier_b4')}}</span>
      </div>
      <div class="col">
        <label>{{ $t('currency.currencyedit.currency.button5') }}</label>
        <input class="form-control" type="number" v-model="data.cashier_b5">
        <span v-if="validerr.get('cashier_b5')" class="validerr">{{validerr.get('cashier_b5')}}</span>
      </div>
      <div class="col">
        <label>{{ $t('currency.currencyedit.currency.button6') }}</label>
        <input class="form-control" type="number" v-model="data.cashier_b6">
        <span v-if="validerr.get('cashier_b6')" class="validerr">{{validerr.get('cashier_b6')}}</span>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-2">
      <button @click="save" type="button" class="btn btn-success" :title="$t('game.game.ok')">Ok</button>
      <button @click="closeEdit" type="button" class="btn btn-danger" :title="$t('game.game.cancel')">{{ $t('game.game.cancel1') }}</button>
    </div>
  </div>
</template>

<script setup>
import CurrencyService from "../../../services/currencie.service";
import {ref, defineEmits, defineProps, inject, onMounted, computed, watch, provide} from "vue";

const $t = inject('$t')

const props = defineProps(['state', 'sel_row']);
const emits = defineEmits(['currencySaved']);

const setState = inject('setState')
const sel_row = inject('sel_row')

const set_result = inject('set_result')

const setIsLoading = inject('setIsLoading')

const data = ref({
  iso: '',
  currency_name: '',
  symbol: '',
  usd: '',
  min_bet: '',
  max_bet: '',
  cashier_b1: '',
  cashier_b2: '',
  cashier_b3: '',
  cashier_b4: '',
  cashier_b5: '',
  cashier_b6: '',
  ex_rate: ''
})

const validatas = inject('validatas')
const validerr = ref(new Map())
const verdata = ref(new Map([
  ['iso', {reg: '[A-Z][A-Z][A-Z]'}],
  ['currency_name', {reg: '.+'}],
  ['symbol', {reg: '.+'}],
  ['usd', {min: 0.000001}],
  ['min_bet', {min: 0.01}],
  ['max_bet', {min: 0.01}],
  ['cashier_b1', {min: 0.01}],
  ['cashier_b2', {min: 0.01}],
  ['cashier_b3', {min: 0.01}],
  ['cashier_b4', {min: 0.01}],
  ['cashier_b5', {min: 0.01}],
  ['cashier_b6', {min: 0.01}]
]));

function closeEdit()
{
  setState('list')
}

function getCurrency()
{
  CurrencyService.getCurrency(sel_row.value).then(
    (response) => {
      data.value = response.data;
      if (data.value.precision == 2) {
        data.value.showDecimal = true
      } else {
        data.value.showDecimal = false
      }

      if(sel_row.value!='') {
        getExchangeRates()
      }
    },
    (error) => {
      set_result(error.response.status, error)
    }
  )
}

function save()
{
  validerr.value = validatas(data.value, verdata.value)

  if(validerr.value.size>0){
    return false
  }

  if (data.value.showDecimal == true){
    data.value.precision = 2
  } else {
    data.value.precision = 0
  }

  CurrencyService.saveCurrency(data.value).then(
      () => {
        emits('currencySaved');
        setState('list')
      },
      (error) => {
        set_result(error.response.status, error)
      }
  )
}

function getExchangeRates()
{
  CurrencyService.getExchangeRates(data.value.iso).then(
    (response) => {
      if(sel_row.value=='') {
        data.value.usd = response.data.exchange_rate;
        data.value.symbol = response.data.symbol;
        data.value.currency_name = response.data.name;
      }

      data.value.ex_rate = '';
      if(response.data.exchange_rate!='') {
        data.value.ex_rate = response.data.exchange_rate;
      }

      setIsLoading(false)
    },
    (error) => {
      set_result(error.response.status, error)
    }
  )
}

function changeISO()
{
  if(sel_row.value=='') {
    setIsLoading(true)
    getExchangeRates()
  }
}

function mounted_fn()
{
  getCurrency()
}
onMounted(mounted_fn)
</script>
